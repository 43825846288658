import React from 'react';
import { Link } from 'gatsby';


import Layout from '../components/layout/Layout';


import PictureWebp from "../components/PictureWebp";
import Button from '../components/Button';

import SplitSection from '../components/SplitSection';






const IndexPage = ({ location }) => (

<Layout location={location} crumbLabel="Inicio" >

<section id="hero-banner" className="bg-banner-soluciones bg-fill bg-right-bottom bg-no-repeat m-0 p-0">
      <div className="container my-auto mx-auto ">
        <div className="bg-gradient-to-r from-white">
          <div className="">

            <div className="bg-white bg-opacity-50 text-center pt-12 p-8 md:text-left md:w-2/5 md:pr-12">
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none text-center">
              Soluciones de Lavandería 
              </h1>
              <p className="text-lg lg:text-xl mt-6 font-normal text-justify text-gray-700">
              Generamos soluciones integrales personalizadas para su lavandería; contribuyendo a la limpieza, higiene, cuidado, conservación y preservación textil de sus prendas. 
              </p>
              <div className="flex flex-wrap mx-auto justify-center mt-8 md:mt-12 mb-20">
        

          <a href="https://walink.co/f18c98" target="_blank"  rel="noreferrer">
          <Button className=" bg-secondary-lighter hover:bg-primary-default rounded-lg active:bg-primary-darker focus:outline-none" size="lg">Contactar a un asesor</Button>
          </a>
              



              </div>
              <p className="mt-4 text-gray-600"></p>
            </div>
          </div>
        </div>
      </div>
</section>

<section id="nosotros" className="py-12  md:py-20 bg-primary-default">
      <div className="container mx-auto">
        <div className="w-full bg-fill bg-no-repeat bg-products">
          <h1 className="text-4xl font-bold text-center text-white">¿Quiénes somos?</h1>
          <p className="md:w-2/3 mx-auto text-2xl font-light text-center my-4 pr-10 pl-10 mb-10 text-white">
          Somos Tecnoclean Ecuador, empresa con presencia internacional que trabaja para brindarle a su compañía, soluciones sostenibles y sistemas integrales para la limpieza, higiene, cuidado, conservación y preservación textil.
          </p>
          <p className="md:w-2/3 mx-auto text-2xl font-semibold text-center my-4 pr-10 pl-10 mb-10 text-white">
          ¡Trabajemos juntos para mejorar la eficiencia y rentabilidad en su operación!
          </p>
        </div>
        </div>
</section>


<section id="nuestro equipo" className=" bg-gray-300 py-2 ">    
<div class="bg-gray-300 px-8 py-2">
  <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
    <div class="space-y-12">
      <div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
        <h2 class="text-primary-default tracking-tight sm:text-3xl text-center text-4xl font-bold mt-0 text-center ">Nuestro Equipo</h2>
      </div>
      <ul role="list" class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-5 lg:gap-x-8">
        <li>
          <div class="space-y-4">
            <div class="aspect-w-3 aspect-h-2 shadow-2xl ">
            <PictureWebp className="object-cover shadow-lg rounded-lg flex justify-center" path="" filename="julieta-carchi" description="Productos de limpieza"/>
            </div>
            <div class="space-y-2">
              <div class="text-2xl leading-6 font-medium space-y-1 text-center text-primary-default ">
                <p>Julieta Carchi</p>
                <p class=" text-primary-default text-center text-base ">Gerente General</p>
                <p className=" font-normal text-gray-900 text-base ">Su pasión son las finanzas y los negocios, su liderazgo nos permite mejorar diariamente y estar en permanente innovación. </p>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="space-y-4">
            <div class="aspect-w-3 aspect-h-2 shadow-2xl">
            <PictureWebp className="object-cover shadow-lg rounded-lg flex justify-center" path="" filename="adriana-flores" description="Asisstente administrativa en Tecnoclean"/>
            </div>
            <div class="space-y-4">
              <div class="text-2xl leading-6 font-medium space-y-1 text-center text-primary-default">
                <p>Adriana Flores </p>
                <p class=" text-primary-default text-center text-base">Asistente Administrativa</p>
                <p className="font-normal  text-black text-base "> Experta en administración y contabilidad, nos apoya en la operación diaria de la compañía
                 </p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="space-y-4">
            <div class="aspect-w-3 aspect-h-2 shadow-2xl">
            <PictureWebp className="object-cover shadow-lg rounded-lg flex justify-center" path="" filename="sebastian-perez" description="Productos de limpieza"/>
            </div>
            <div class="space-y-2">
              <div class="text-2xl leading-6 font-medium space-y-1 text-center text-primary-default">
                <p>Sebastián Pérez</p>
                <p class="text-primary-default text-center text-base">Supervisor de Ventas </p>
                <p className="font-normal  text-black text-base "> Experto comercial y técnico en procesos de lavanderías industriales, hoteleras y hospitalarias. 
                </p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="space-y-4">
            <div class="aspect-w-3 aspect-h-2 shadow-2xl">
            <PictureWebp className="object-cover shadow-lg rounded-lg flex justify-center" path="" filename="christian-zambrano" description="Brandon Carchi"/>
            </div>
            <div class="space-y-2">
              <div class="text-2xl leading-6 font-medium space-y-1 text-center text-primary-default ">
                <p>Christian Zambrano </p>
                <p class="text-primary-default text-center text-base">Asesor Técnico</p>
                <p className="font-normal  text-black text-base ">Experto en lavandería industrial, domina el how know técnico en manejo de equipos industriales.

                </p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="space-y-4">
            <div class="aspect-w-3 aspect-h-2 shadow-2xl">
            <PictureWebp className="object-cover shadow-lg rounded-lg flex justify-center" path="" filename="paul-ardilla" description="Productos de limpieza"/>
            </div>
            <div class="space-y-2">
              <div class="text-2xl leading-6 font-medium space-y-1 text-center text-primary-default ">
                <p>Paúl Ardila </p>
                <p class="text-primary-default text-center text-base">Asesor Técnico</p>
                <p className="font-normal text-black text-base ">Su experiencia permite a la compañía contar con un seguimiento periódico a los clientes, con un servicio profesional. 

                 </p>
              </div>
            </div>
          </div>
        </li>
        <li>
         
        </li>
      </ul>
    </div>
  </div>
</div>
</section>

<section id="silg" class="bg-primary-default pt-2">
  <div class="max-w-7xl mx-20 py-16 px-4 sm:px-6 lg:py-24 lg:px-8 bg-primary-default">
    <div class="max-w-2xl mx-auto text-center">
      <h1 class="text-4xl font-bold mt-0 text-center text-white ">¿Cómo lo hacemos?</h1>
    </div>
    </div>
</section>

<section class="  pb-20  grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mx-0 px-2 bg-primary-default">
  <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 ">
    <div class="flex-1 flex flex-col p-8 ">
    <PictureWebp className="flex justify-center " filename="silg-1" description="responsabilidad social unilimpio"/>
      <h3 class="mt-6 text-secondary text-lg font-bold ">1. Diagnóstico y elaboración de propuesta técnico - económica:</h3> <br/>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dt class="sr-only">Title</dt>
        <dd class="text-gray-700 text-base text-justify">Identificación de la situación actual y condiciones acuales de la lavandería y elaboración de propuestas de mejoramiento en calidad de lavado.</dd>
        <dt class="sr-only">Role</dt>
        <dd class="mt-3">

        </dd>
      </dl>
    </div>
  </li>

  <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div class="flex-1 flex flex-col p-8">
    <PictureWebp className="flex justify-center" filename="silg-2" description="responsabilidad social unilimpio"/>
      <h3 class="mt-6 text-secondary text-lg font-bold">2. Implementación:</h3><br/>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dt class="sr-only">Title</dt>
        <dd class="text-gray-700 text-base text-justify"><ul class="list-disc text-gray-700 text-base text-justify" ><li>Procesos de lavado eficaces, rentables con alta calidad de lavado.</li><li>Estandarización de procesos de lavado.</li> <li>Implementación de equipos de dosificación de última tecnología, por telemetría.</li>  <li>Capacitación y entrenamiento.</li> </ul></dd>
        <dt class="sr-only">Role</dt>
        <dd class="mt-3">

        </dd>
      </dl>
    </div>

  </li>

  <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div class="flex-1 flex flex-col p-8">
    <PictureWebp className="flex justify-center" filename="silg-3" description="responsabilidad social unilimpio"/>
      <h3 class="mt-6 text-secondary text-lg font-bold">3. Asistencia, seguimiento y control:</h3><br/>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dt class="sr-only">Title</dt>
        <dd class="text-gray-700 text-base text-justify">Validación y verificación del cumplimiento de los resultados Seguimiento del proceso de lavado.</dd>
        <dt class="sr-only">Role</dt>
        <dd class="mt-3">

        </dd>
      </dl>
    </div>

  </li>
</section>





<div id="video-responsive" className=" bg-banner-video flex justify-center  py-24"> 
    <iframe width="590" height="345" 
        src="https://www.youtube.com/embed/AFrYtvI0hY0" 
        title="video institucional Tecnoclean" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
</div>






<section id="nuestros clientes" className="bg-gray-200 py-12">
<div class="bg-gray-200">
  <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <p class="text-center  text-5xl font-semibold text-primary-default">
      Nuestros Clientes
    </p><br/>
    <div class="mt-6 grid grid-cols-2 gap-4 md:grid-cols-5 lg:mt-8">
      <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <PictureWebp className="" filename="logo-jwmarriott" description="responsabilidad social unilimpio"/>
      </div>
      <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <PictureWebp className="" filename="logo-solca" description="responsabilidad social unilimpio"/>
      </div>
      <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <PictureWebp className="" filename="logo-metropolitano" description="responsabilidad social unilimpio"/>
      </div>
      <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <PictureWebp className="" filename="logo-clean-fresh" description="responsabilidad social unilimpio"/>
      </div>
      <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <PictureWebp className="flex justify-center" filename="logo-hospital-de-rio" description="responsabilidad social unilimpio"/>
      </div>
      <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <PictureWebp className="" filename="logo-mantahost" description="responsabilidad social unilimpio"/>
      </div>
      <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <PictureWebp className="" filename="logo-hermanas-hospitalarias" description="responsabilidad social unilimpio"/>
      </div>
      <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <PictureWebp className="" filename="logo-decameron" description="responsabilidad social unilimpio"/>
      </div>
      <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <PictureWebp className="" filename="logo-hilton" description="responsabilidad social unilimpio"/>
      </div>
      <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <PictureWebp className="" filename="logo-junta-beneficiencia" description="responsabilidad social unilimpio"/>
      </div>
    </div>
  </div>
</div>
</section>


<section id="catalogo" className=" bg-primary-default   ">
<SplitSection id="catalogo" className="bg-gradient-to-r from-primary-default via-white to-primary-default py-12 "
      
      primarySlot={
        <div className=" bg-white bg-opacity-50 rounded-lg  py-6 mx-2">
          <h2 className="text-center sm:text-3xl text-2xl font-medium title-font  text-primary-default">Catálogo de Productos</h2><br/>
          <p className="md:w-full text-left leading-relaxed text-lg text-gray-900 text-justify:center pr-10 pl-10">
          Encuentra los productos de desinfección más eficientes del mercado para la limpieza, higiene, cuidado, conservación y preservación textil.
          </p>
          <div className="w-full mx-auto mb-2">
          <a href="../files/catalogo-tecnoclean-2022.pdf" className="" target="_blank"  rel="noreferrer">
                    <Button className="flex mx-auto mt-16 text-white bg-secondary-lighter border-0 py-2 px-8 focus:outline-none hover:bg-primary-default rounded text-lg">Descargar Catálogo </Button>
                    </a>
                  

                </div>
        </div>
      }
      secondarySlot={<PictureWebp className="flex justify-center " filename="productos-tecnoclean" description="Técnicos cazabacterias realizando una desinfección profunda de lugares con aglomeración de personas y poca ventilación natural y exposición a la luz solar, como medida de prevención de la transmisión indirecta del COVID-19."/>}
/></section>



<section className="bg-gray-200 flex justify-center  ">
  <div className="max-w-7xl mx-10 py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
    <div className="max-w-3xl mx-auto text-center">
      <h1 className="text-5xl font-semibold text-primary-default ">Política de Calidad</h1>
      <p className="mt-4 text-xl text-primary-default mb-20">Trabajamos con altos estándares internacionales, basados en 3 ejes clave:  </p>
    </div>
    <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
      <div className="flex">
        <div className="ml-3">
        <PictureWebp className="flex justify-center" filename="cuidado-del-ambiente" description="responsabilidad social unilimpio"/> 
        <svg className="flex-shrink-0 h-6 w-6 text-secondary-lighter text-right  " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
          <dt className="text-2xl leading-6 font-medium  text-center text-primary-default mb-6">
          Cuidado del Ambiente:
          </dt>
          <dd className="mt-2 text-base text-gray-700 text-justify">
          Reducimos el consumo de agua a través de la instalación de equipos de última tecnología, optimizando los tiempos de lavado y reprocesos en su lavandería y reduciendo el uso de plástico.
          </dd>
        </div>
      </div>

      <div className="flex">
        <div className="ml-3">
        <PictureWebp className="flex justify-center" filename="respeto" description="responsabilidad social unilimpio"/> 
        <svg className="flex-shrink-0 h-6 w-6 text-secondary-lighter" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
          <dt className="text-2xl leading-6 font-medium text-primary-default text-center mb-6">
           Respeto: 
          </dt>
          <dd className="mt-2 text-base text-gray-700 text-justify">
          Encaminamos las relaciones con nuestros clientes y proveedores, enfatizando en el cumplimiento de acuerdos en el tiempo y condiciones pactadas, fomentando una relación comercial a largo plazo. 
          </dd>
        </div>
      </div>

      <div className="flex">
        <div className="ml-3">
        <PictureWebp className="flex justify-center" filename="servicio-integral" description="responsabilidad social unilimpio"/> 
        <svg className="flex-shrink- h-6 w-6 text-secondary-lighter" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
          <dt className="text-2xl leading-6 font-medium text-primary-default text-center mb-6">
          Servicio Integral: 
          </dt>
          <dd className="mt-2 text-base text-gray-700 text-justify">
          Damos soluciones eficientes, en el menor tiempo posible y con atención personalizada, porque entendemos que cada empresa es diferente y merece un trato prioritario a todo momento. 

          </dd>
        </div>
      </div>
    </dl>
  </div>
</section>


<section id="sostenibilidad" className=" bg-secondary-lighter ">
<SplitSection id="sostenibilidad"
      reverseOrder className="bg-gradient-to-r from-secondary-lighter via-white to-secondary-lighter py-12 "
      primarySlot={
        <div className=" bg-white bg-opacity-50 rounded-lg  py-6 mx-2 ">
       <h2 className="text-center sm:text-3xl text-2xl font-medium title-font  text-primary-default">Sostenibilidad</h2><br/>
          <p className="md:w-full text-left leading-relaxed text-base text-gray-700 text-justify:center pr-10 pl-10">
          En Tecnoclean Ecuador brindamos a nuestros clientes, un servicio integral con soluciones que les permitan mejorar la eficiencia de sus procesos y ser sostenibles; a través de: 
          </p><br/>
          <ol class="list-decimal md:w-full text-left leading-relaxed text-base text-gray-700 text-justify pr-10 pl-10">
  <li> Cumplimiento de la normativa local vigente de consumo de agua y manejo de  residuos. </li> 
  <li>Reducción de desechos plásticos por la reutilización de canecas. </li>
  <li>Optimización de procesos de lavado controlados para reducción de consumo de electricidad y hora hombre.</li> </ol>

        </div>
      }
      secondarySlot={<PictureWebp className="flex justify-center " filename="sostenibilidad" description="Tecnoclean se preocupa por el medio ambiente."/>}
/>
</section>



<section id="contactenos" className="bg-primary-default">
<div class="relative bg-white">
  <div class="absolute inset-0">
    <div class="absolute inset-y-0 left-0 w-1/2 bg-primary-default"></div>
  </div>
  <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
    <div class="bg-primary-default py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
      <div class="max-w-lg mx-auto">
        <h2 class="text-2xl font-extrabold  text-white sm:text-3xl">
          Contáctenos
        </h2>
        <dl class="mt-8 text-base text-white">
          <div>
            <dt class="sr-only">Postal address</dt>
            <dd>
              <p>De los Eucaliptos E1-140 y Psje. Juncos 170307</p>
              <p>Quito, Ecuador</p>
            </dd>
          </div>
          <div class="mt-6">
            <dt class="sr-only">Phone number</dt>
            <dd class="flex">
             
              <svg class="flex-shrink-0 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              <span class="ml-3">
              (+593) 99 488 9634
              </span>
            </dd>
          </div>
          <div class="mt-3">
            <dt class="sr-only">Email</dt>
            <dd class="flex">
              
              <svg class="flex-shrink-0 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <span class="ml-3">
                info@tecnocleanecuador.ec 
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div class="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
      <div class="max-w-lg mx-auto lg:max-w-none">
        <form name="lead" action="/gracias" method="POST" data-netlify="true" netlify-honeypot="bot-field" class="grid grid-cols-1 gap-y-6">
          <div>
            <label for="full-name" class="sr-only">Nombre</label>
            <input type="text" name="nombre" id="nombre" autocomplete="name" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="Nombre"/>
          </div>
          <div>
            <label for="email" class="sr-only">Email</label>
            <input id="email" name="email" type="email" autocomplete="email" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="Correo"/>
          </div>
          <div>
            <label for="phone" class="sr-only">Teléfono</label>
            <input type="text" name="phone" id="phone" autocomplete="tel" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="Teléfono"/>
          </div>
          <div>
            <label for="mensaje" class="sr-only">Mensaje</label>
            <textarea id="mensaje" name="mensaje" rows="4" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md" placeholder="Mensaje"></textarea>
          </div>
          <div>
            <button type="submit" class="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-secondary-lighter hover:bg-primary-default focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</section>






  </Layout>

)

  export default IndexPage
